
.thank-you-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.thank-you-page .thank-you-logo {
    width: auto;
}

.thank-you-page .thank-you-footer-content {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.thank-you-page .thank-you-footer-content h2 {
    color: var(--bg-dark);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.thank-you-page .thank-you-footer-content p {
    color: var(--light-gray);
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.thank-you-page .shop-more {
    border-radius: 10px;
    background: var(--brand-color);
    max-width: 600px;
    width: 100%;
}

.thank-you-page .shop-more button {
    color: var(--white);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    border: none;
    background: transparent;
    height: 50px;
}