/* CSS Used fontfaces */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root {
  --brand-color: #FF8D08;
  --error-color: #FF0000;
  --border-color: #C1C1C1;
  --text-accent-color: #0071BC;
  --input-border-color: #E3E3E3;
  --grey-text: #6C6A6A;
  --grey-bg: #F9F9F9;
  --bg-dark: #000000;
  --light-gray: #6C6A6A;
  --light-gray-color: #CFCFCF;
}

html,
body,
.container {
  height: 100%;
  font-family: Lato;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  min-height: 100vh;
}

.mb-20 {
  margin-bottom: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.fs-16 {
  font-size: 16px;
}

.mb-lg-30 {
  margin-bottom: 30px;
}

.flex-basis-unset {
  flex-basis: unset !important;
}

.text-brand {
  color: var(--brand-color) !important;
  font: 12px Lato;
  margin-top: 10px;
}

button:focus {
  box-shadow: none !important;
}

button {
  cursor: pointer !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cursor-text {
  cursor: text !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.error-msg {
  color: #F00 !important;
  font-family: Lato;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.alert {
  position: fixed !important;
  top: 10%;
  z-index: 31;
  right: 0;
  word-break: break-all;
  width: auto;
}

.text-decoration-underline {
  text-decoration: underline;
  cursor: pointer;
}

input:disabled {
  opacity: 0.6;
}

.centered {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@media screen and (max-width: 768px) {

  .mb-xs-20 {
    margin-bottom: 20px;
  }
}