.navbar {
  background-color: rgb(0 0 0);
  box-shadow: 0 14px 36px 0 rgba(0, 0, 0, 0.06);
  justify-content: space-between !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 5 !important;
  min-height: 55px !important;
}

.navbar-dark .navbar-text a {
  font-size: 14px !important;
}

.hamburger-menu {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.hamburger-menu a {
  text-decoration: none;
  color: var(--white);
  transition: color 0.3s ease;
}

.hamburger-menu input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

.hamburger-menu span {
  display: block;
  width: 26px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

.hamburger-menu span:first-child {
  transform-origin: 0% 0%;
}

.hamburger-menu span:nth-last-child(2) {
  transform-origin: -12% 100%;
}

.hamburger-menu input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, 1px);
  background: var(--white);
  width: 22px;
}

.hamburger-menu input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.hamburger-menu input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

.menu-list {
  position: fixed;
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 0;
  right: 0;
  top: 0;
  background: var(--bg-dark);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

}

.menu-list li {
  padding: 10px 0;
  font-size: 22px;
}

.hamburger-menu input:checked~ul {
  transform: none;
}

ul {
  display: flex;
  flex-direction: column;
}

.timeline-ul{
  display: flex;
  flex-direction: row;
}

.icons {
  gap: 20px;
}

.support-dd {
  display: none !important;
  font-size: 14px;
  font-family: LatoRegular;
  word-wrap: break-word;
  border-radius: 10px;
  background-color: var(--text-white);
  padding: 15px 32px 10px 15px;
  position: fixed;
  z-index: 10;
  flex-direction: column;
  top: 63px;
  right: 15%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

ul.support-dd li a {
  color: var(--gray-dark) !important;
  text-decoration: none;
}

ul.support-dd li {
  margin-bottom: 15px !important;
  color: var(--bg-dark) !important;
  font-size: 14px !important;
  font-family: 'basic-sans';
  word-wrap: break-word;
  padding: 0px !important;
}

#support:hover ul.support-dd {
  display: flex !important;
  right: 15%;
  list-style: none;
  background: var(--white);
  top: 55px;
}

#support {
  color: var(--light-gray-color) !important;
  font-size: 14px;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown svg {
  margin-left: 8px;
}

.dropdown-content {
  display: none;
  position: absolute;
  max-height: 500px;
  background: #292929 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ACACAC29;
  width: 100%;
  left: 0;
  z-index: 9999;
  top: 114px;
  min-width: 100%;
  min-height: 100px;
}

.dropdown-content a {
  float: none;
  font: normal normal bold 14px/18px Lato !important;
  letter-spacing: 0.29px;
  text-decoration: none;
  display: inline-block !important;
  text-align: left;
  padding: 15px 0;
}

.dropdown:hover>a {
  color: var(--text-white);
}

.dropdown:hover .dropdown-content {
  display: flex;
  justify-content: space-between;
}

.dropdown #dropdown-items {
  display: flex;
  flex-direction: column;
  padding: 46px 51px;
  width: 30%;
}

.help-icon {
  display: block !important;
  width: 49px;
  height: 24px;
  background: #141414 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 12px;
  font: normal normal normal 12px/15px LatoRegular;
  color: #FFFFFF;
  text-align: center;
  line-height: 21px;
  font-family: sans-serif;
}

li#wireless-earbuds {
  position: relative;
}

li#wireless-earbuds:after {
  background: #009688;
  content: 'new';
  position: absolute;
  top: -13px;
  right: 10px;
  padding: 0 5px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(-3deg);
  }
}

.hamburger-list-scrollable {
  overflow-y: auto;
  height: 75%;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

@media screen and (max-width: 768px) {

  .timeline-ul{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .hamburger-menu {
    display: none !important;
  }

  .category-menu-list {
    display: block !important;
  }
  .category-menu-list ul a {
    color: var(--light-gray-color) !important;
  }

  .help-icon {
    display: none !important;
  }
  .navbar {
    padding: 0 40px !important;
    height: 80px;
  }


}

@media screen and (max-width: 768px) {
  .navbar .navbar-brand img {
    width: 60%;
  }
  .navbar {
    padding-left: .6rem !important;
  }
  .help-icon {
    display: block !important;
    width: 49px;
    height: 24px;
    background: #141414 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    font: normal normal normal 12px/15px LatoRegular;
    color: #FFFFFF;
    text-align: center;
    line-height: 21px;
    font-family: sans-serif;
  }

  .category-menu-list {
    display: none !important;
  }

  .hamburger-menu {
    display: block !important;
  }

  .menu-list li {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  #mobile-support ul li a {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  #mobile-support {
    position: absolute;
    background-color: var(--bg-dark);
    width: 100%;
    height: 100%;
    right: -1000px;
  }

  #mobile-support.slide-in-support {
    animation: slide-in-support 1.5s forwards;
  }

  @keyframes slide-in-support {
    0% {
      right: -1000px;
    }

    50% {
      right: -50px;
    }

    100% {
      right: 0px;
    }
  }


  .need-help {
    display: flex !important;
    justify-content: space-between !important;
    background: #FF8D08 0% 0% no-repeat padding-box;
    border: 1px solid #FF8D08 !important;
    opacity: 1;
    margin: 10px auto;
    position: relative;
    border: 0px !important;
    width: fit-content;
    padding: 15px !important;
    height: 41px;
    overflow: hidden;
    border-radius: 10px;
    width: 90%;
  }

  .need-help span {
    border: none !important;
    background-color: transparent !important;
    height: 100% !important;
    width: auto !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center;
    font: 14px LatoBold !important;
    gap: 5px;
    color: #fff;
  }

  .need-help:after {
    content: "";
    width: 30px;
    height: 100px;
    position: absolute;
    left: -20px;
    top: 0;
    margin-top: -20px;
    background: #fff;
    opacity: 0.6;
    filter: blur(20px);
    animation: shine 2.5s infinite;
    animation-delay: 0s;
    transform-origin: top;
  }

  @keyframes shine {
    from {
      transform: translate(0) rotate(20deg);
    }

    to {
      transform: translateX(380px) rotate(20deg);
    }
  }
}