#request-docs-wrapper {
    margin-top: 100px;
    .container {
        padding: 40px;
        width: 50% !important;
    }
    .font-weight-bold {
        color: var(--text-accent-color);
        display: inline;
        font-size: 18px;
    }
    h5 {
        color: var(--text-accent-color);
    }
    .details {
        display: flex;
        text-align: left;
    }
    #remarks {
        display: inline-block;
        margin-bottom: 30px;
    }
    section {
        display: flex;
        gap: 20px;
        div {
            position: relative;
            border: 2px dashed var(--text-accent-color);
            border-radius: 4px;
            height: 60px;
            width: 100%;
            input {
                height: 100%;
                width: 100%;
            }
            label {
                position: absolute;
                left: 0;
                height: 100%;
                width: 100%;
                border-radius: 4px;
                text-align: center;
                line-height: 55px;
                font-size: 20px;
                background-color: #fff;
                cursor: pointer;
            }
        }
    }
    .file-box-wrapper {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .file-box {
            width: 70px;
            height: 70px;
            box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
            display: inline-block;
            padding: 10px;
            border-radius: 5px;
            word-break: break-all;
            position: relative;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            button {
                position: absolute;
                top: -10px;
                right: -10px;
                height: 30px;
                width: 30px;
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
            svg {
                height: inherit;
                cursor: pointer;
                width: auto;
            }
        }
    }
    textarea {
        border-color: var(--text-accent-color);
        border-width: 1px;
        border-radius: 4px;
        width: 100%;
        height: 120px;
        margin-bottom: 20px;
        padding: 10px;
        outline: none;
    }
    .btn-brand {
        width: 50%;
        cursor: pointer !important;
        background-color: var(--brand-color) !important;
        border-color: var(--brand-color) !important;
        height: 50px;
        margin-top: 20px;
        font-weight: 500;
        font-size: 20px;
        outline: none;
        margin: 50px auto;
        display: block;
        cursor: pointer;
        &:hover {
            background-color: var(--brand-color);
            border-color: var(--brand-color);
        }
        &:disabled {
            opacity: 0.7;
        }
    }
}
@media screen and (max-width: 768px) {
    #request-docs-wrapper .container {
        width: 90% !important;
    }
    .details {
        flex-direction: column;
        p{
            word-break: break-all;
        }
    }
    #request-docs-wrapper .container {
        padding: 25px;
    }
    #request-docs-wrapper .btn-brand {
        width: 100% !important;
    }
    section {
        flex-direction: column;
    }
    #request-docs-wrapper h5 {
        font-size: 20px;
    }
}