.container {
    background: white;
    width: 100% !important;
    margin: 30px auto;
    max-width: unset !important;
    height: auto !important;
    padding: 0px !important;

    h3 {
        color: var(--brand-color);
        font-family: Lato;
        font-size: 32px;
        font-weight: 400;
    }

    #header {
        max-width: 64.422% !important;
        margin-inline: auto;
        margin-top: 60px;
    }
}
.catName{
    width: 40%;
    // margin: 0 auto;
}

.w-5 {
    width: 20px;
}

.h-5 {
    height: 20px;
}

.error {
    display: block;
    margin-top: 5px;
    text-align: left;
    font-size: 12px;
    color: var(--error-color);
}

.border-error {
    border-color: var(--error-color) !important;
}

.progress-wrapper {
    display: flex;
    grid-template-columns: repeat(5, max-content);
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    position: relative;
    width: 90%;
    margin: 20px auto 57px auto;
    align-items: center;

    .progress-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        position: relative;

        .step {
            border: 2px solid var(--border-color);
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #fff;
            font: 18px Lato;
            color: var(--border-color);
            align-items: center;
            display: flex;
            justify-content: center;
        }

        span:nth-of-type(2) {
            margin-top: 10px;
            color: var(--border-color);
            font: 14px Lato !important;
            white-space: nowrap;
            position: absolute;
            bottom: -32px;

        }
    }


    .line-bar {
        .line {
            width: -webkit-fill-available;
            height: 2px;
            border-top: 2px solid var(--border-color);
            position: absolute;
        }

        .step-completed {
            width: 50%;
            position: absolute;
            border-color: var(--blue);
            z-index: 1;
        }

        .half-step-completed {
            position: absolute;
            width: 50%;
            right: 0;
            border-color: var(--blue);
            z-index: 1;
        }
    }

    .step-completed {
        .step {
            background-color: var(--text-accent-color) !important;
            border-color: var(--text-accent-color) !important;
        }

        span:nth-of-type(2) {
            color: #000 !important;
        }
    }

    .active-step {
        .step {
            color: var(--text-accent-color) !important;
            border-color: var(--text-accent-color) !important;
            font-weight: bold !important;
        }

        & span:nth-of-type(2) {
            font-weight: bold !important;
            color: var(--text-accent-color) !important;
        }
    }
}

form {
    padding: 0px 0px 45px 0px;
    width: 70.59%;
    margin: 0px auto 0 auto;

    fieldset {
        margin: 0 auto;
    }

    .btn-brand {
        width: 100%;
        max-width: 500px;
        cursor: pointer !important;
        background-color: var(--brand-color) !important;
        border-color: var(--brand-color) !important;
        height: 50px;
        font-weight: bold;
        font-size: 16px;
        outline: none;
        border-radius: 10px;
        margin-top: 30px;

        &:hover {
            background-color: var(--brand-color);
            border-color: var(--brand-color);
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed !important;
        }
    }
}

// Form 1 scss

.did-floating-label-content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 30px;
    position: relative;
}

.did-floating-label {
    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
}

.did-floating-input,
.did-floating-select {
    border-radius: 10px;
    border: 1px solid var(--input-border-color);
    background: #FFF;
    padding: 15px 10px;
    width: 100%;
    height: 50px;
    font: 16px Lato;

    &[type="date"] {
        min-width: 94%;
        display: flex;
        display: -webkit-flex;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #C1C1C1;
    }
}

select.did-floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.did-floating-select::-ms-expand {
    display: none;
}


.did-error-input {

    .did-floating-input,
    .did-floating-select {
        border: 2px solid #9d3b3b;
        color: #9d3b3b;
    }

    .did-floating-label {
        font-weight: 600;
        color: #9d3b3b;
    }

    .did-floating-select:not([multiple]):not([size]) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
    }
}

.input-group {
    display: flex;

    .did-floating-input {
        width: 100%;
        border-radius: 0 4px 4px 0;
        border-left: 0;
        padding-left: 0;
    }
}

.input-group-append {
    display: flex;
    align-items: center;
}

.input-group-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    height: 34px;
    color: #323840;
    padding: 0 5px 0 20px;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #3D85D8;
    border-radius: 4px 0 0 4px;
    border-right: none;
}

.ml-10 {
    margin-left: 10px;
}

#otp-form {
    p {
        font-size: 22px;
        display: inline-block;
        margin-bottom: 10px;
        font-weight: 500;
        font-family: Lato;
        text-align: center;
        color: #000;
    }

    #change-number {
        font: bold 16px Lato;
        display: block;
        margin-bottom: 30px;
        color: #000;
    }

    .text-brand {
        font: 16px Lato;
        margin-top: 0px;
    }

    .input-group {
        gap: 24px;
        margin-bottom: 30px;
    }

    .form-control--otp {
        width: 80px;
        height: 50px !important;
        border-radius: 10px;
        border: 1px solid #E3E3E3;
        background: #FFF;
        text-align: center;
        font: bold 16px Lato;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus {
            outline: none;
        }
    }

    .form-control {
        margin-bottom: 0px !important;
    }

    .did-floating-label-content {
        margin-bottom: 0px;
    }
}

#personal-details {
    display: flex;
    width: 70%;
    margin: 0 auto;
    margin-top: 30px;

    span {
        font-weight: bold;
        color: #1e61aa
    }
}

#form3 {
    width: 87.8477306%;
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    background: #FFF;

    input::placeholder {
        color: #C1C1C1;
    }

    .tabs {
        background-color: var(--grey-bg);
        border: 1px solid var(--input-border-color);
        overflow-x: auto;
        cursor: pointer;

        span {
            padding: 20px 54px;
            text-transform: capitalize;
            color: var(--grey-text);
            text-align: center;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            flex-grow: 1;

            &.active {
                background-color: #fff;
                border-bottom: 3px solid var(--text-accent-color);
                font: bold 16px Lato;
                color: var(--text-accent-color);
            }
        }
    }

    .fields-wrapper {
        padding: 20px 24px;
        display: flex;
        gap: 0 20px;
        flex-wrap: wrap;
        border-top: 0px;
    }

    #price {
        position: absolute;
        left: 0;
        top: 34px;
        background-color: var(--grey-bg);
        width: 50px;
        height: 50px;
        z-index: 1;
        text-align: center;
        line-height: 48px;
        font-size: 20px;
        border-radius: 4px 0 0 4px;
        border: 1px solid var(--border-color);

    }

    #commsWrapper {
        background-color: #fff5ed;
        border: 1px solid var(--brand-color);
        border-radius: 5px;
        text-align: left;
        padding: 16px 19px;
        font-size: 14px;
    }

    .form-check {
        text-align: left;
        font-size: 18px;
        margin-bottom: 20px;

        .form-check-input {
            width: 20px;
            height: 20px;
        }

        .form-check-label {
            padding-left: 8px;
        }
    }

    #product-details {
        .did-floating-label-content {
            flex-basis: 49%;
        }
    }

    #purchase-details {
        .did-floating-label-content {
            flex-basis: 23.479%;
        }

        .upload-wrapper {
            .text-brand {
                margin-bottom: 20px;
            }
        }

        .btn-outline {
            margin-top: 40px;
        }
    }

    iframe {
        border-radius: 10px;
        border: none;
    }

    #issue-details {
        padding: 20px;
        gap: 18px;

        .did-floating-label-content {
            flex-basis: 48.223%;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }

        .note {
            color: #6C6A6A;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .btn-outline {
            margin-bottom: 0px;
        }

        .w-70 {
            width: 270px;
            flex-grow: 1;

            span {
                color: #000;
                display: block;
                margin-bottom: 20px;
                text-align: center;
                font-family: Lato;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .flex-column {
                width: fit-content;
                gap: 10px;
                position: relative;

                svg {
                    position: absolute;
                    right: 0;
                    top: 85px;

                    &:nth-of-type(2) {
                        right: 10px;
                    }
                }
            }

            .sample-video-section {
                position: relative;
                cursor: pointer;

                .thumbnail-overlay {
                    position: absolute;
                    z-index: 1;
                    background-color: #000;
                    opacity: 0.6;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 20px;
                }

                svg {
                    position: absolute;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                }
            }

            img {
                width: 300px;
                height: 148px;
                border-radius: 10px;
            }
        }
    }

    .file-box-wrapper {
        margin-top: 10px;
    }

    .form-input-group {
        height: 50px;
        // background-color: aliceblue;
        border-radius: 20px;
        background: #F9F9F9;
        border: 1px solid #F9F9F9;
        margin-bottom: 30px;

        span {
            color: #C1C1C1;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        div.position-relative {
            border-radius: 10px;
            border: 1px solid #E3E3E3;
            background: #FFF;
            width: 550px;
            height: 50px;
            flex-shrink: 0;
            padding: 15px 20px;

            span {
                display: block;
                text-align: left;
            }

            #calendar {
                top: 12px;
                cursor: pointer;
            }

            .text-brand {
                margin-top: 5px;
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: inherit;
                opacity: 0;
            }
        }
    }

    .bg-grey {
        .note {
            color: #6C6A6A;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 10px;
        }

        background: #F9F9F9;
        border-radius: 20px;
        padding: 20px;
        display: flex;
        gap: 0 205px;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;

        .form-input-group {
            background-color: transparent;
            border: none;
            height: auto;
        }
    }

    .file-upload-wrapper {
        display: flex;
        gap: 0 205px;
    }

    .btn-outline {
        width: 250px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid var(--text-accent-color);
        background: #FFF;
        color: var(--text-accent-color);
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 10px auto 20px auto;

        &:disabled {
            color: #B3D4EB;
            border-color: #B3D4EB;
            opacity: 1;
        }
    }

    .form-input-group {
        border-radius: 10px;
        border: 1px solid var(--input-border-color);
        background: #FFF;
        cursor: pointer !important;
        margin-bottom: 0px;
    }

    .file-label {
        margin-bottom: 10px;
        text-align: left;
        display: block;
        color: #000;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .upload-wrapper {
        flex-basis: 40%;

        input[type="file"] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: inherit;
            opacity: 0;
        }

        .centered {
            color: var(--border-color);
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            left: 25%;
        }

        svg.centered {
            right: 20px;
            left: unset;
            pointer-events: none;
        }

        .text-brand {
            font-size: 14px;
            margin-top: 5px;
        }
    }

    #info-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 20px;
        margin-top: 40px;
        width: 100%;

        .bg-grey {

            & span:first-child {
                color: #000;
                font-family: Lato;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .item {
            display: flex;
            margin-top: 20px;
            gap: 40px;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            span {
                display: inline-block;
                color: #000;
                text-align: center;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                height: 100%;
            }
        }
    }

    .form-input-group {
        height: 50px;
    }

    h4 {
        text-align: left;
        font-size: 18px;
    }

    .address-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;

        .did-floating-label-content {
            flex-basis: 24.34%;
        }

        .did-floating-label-content:first-child {
            flex-basis: 100%;
        }
    }

    #calendar {
        pointer-events: none;
        position: absolute;
        right: 9px;
        top: 46px;
        display: inline-block;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 20px;
        background-color: #FFF;
        cursor: pointer;
    }

    .btn-brand {
        max-width: 600px;
        margin-inline: auto;
    }

    .file-box-wrapper {
        display: flex;
        gap: 20px;
        width: 100%;
        margin-bottom: 20px;
        flex-wrap: wrap;

        .file-box-image {
            width: 80px;
            height: 80px;
            border: 1px solid #E3E3E3;
            border-radius: 10px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;

            button {
                border: none;
                display: none;
                background-color: #00000099;
                width: 90%;
                height: 90%;
                border-radius: 10px;
                margin: auto;
                align-items: center;
                justify-content: center;
                gap: 10px;

                span {
                    height: 30px;
                }
            }

            &:hover button {
                display: inline-flex;
            }
        }

        .file-box {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 0px;
            word-break: break-all;
            color: #0071BC;
            font: 16px Lato;


            span {
                text-overflow: ellipsis;
                /* enables ellipsis */
                white-space: nowrap;
                /* keeps the text in a single line */
                overflow: hidden;
                width: auto;
                max-width: 250px;
            }

            button {
                cursor: pointer;
                border: none;
                background-color: transparent;
            }

            svg {
                height: inherit;
                cursor: pointer;
                width: auto;
            }
        }
    }

    .product-cards-wrapper {
        text-align: left;
    }

    .product-card {
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        display: flex;
        gap: 10px;
        height: fit-content;
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
    }

    .custom-tick {
        height: fit-content;

        label {
            color: #6c757d;
        }
    }

    .file-upload {
        position: relative;
        border: 1px solid hsla(206, 100%, 73.3%, 1);
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
        width: 40%;
        height: 60px;
        line-height: 32px;
        cursor: pointer;
        font-weight: 600;

        input {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            opacity: 0;
        }
    }

}

#form3 {
    #issue-details {
        .file-box-wrapper {
            .file-box {
                span {
                    max-width: unset;
                }
            }
        }
    }
}

#purchase-details {
    .file-box {
        span {
            max-width: 220px !important;
        }
    }
}

#delivery-address {
    .did-floating-label-content {
        flex-basis: 24.34%;
    }

    .did-floating-label-content:first-child {
        flex-basis: 100%;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.checkbox-wrapper {
    position: relative;
    display: block;
    position: relative;
    font-size: 16px;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    width: fit-content;
    user-select: none;
}

.checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    left: 0;
    top: 3px;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 1px solid #6C6A6A;
    background: #FFF;
    margin-right: 10px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrapper input:checked~.checkmark {
    background-color: var(--text-accent-color);
    border-color: var(--text-accent-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.4233 1.51334L4.43663 7.74002C4.10329 8.08666 3.57 8.08666 3.23667 7.74002L0.25 4.60668C-0.0833335 4.26004 -0.0833335 3.70006 0.25 3.35332C0.583334 3.00668 1.11662 3.00668 1.44995 3.35332L3.84996 5.85999L9.23663 0.259983C9.56997 -0.0866608 10.1033 -0.0866608 10.4366 0.259983C10.7566 0.606626 10.7566 1.1667 10.4233 1.51334Z" fill="white"/></svg>');
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark:after {
    left: 3px;
    top: -6px;
}

.video-popup .modal-content {
    background: transparent !important;
    border: unset !important;
}

.images {
    .modal-content {
        background-color: #fff !important;
        border: 1px solid var(--border-color) !important;

        .modal-header {
            position: relative !important;
            right: unset !important;
            top: unset !important;
            width: 100%;
            padding: 0 10px 10px;
            height: 40px;

            button {
                padding: 0;
                font-size: 50px;
                margin: 0;
                display: flex;
                width: 100%;
                justify-content: flex-end;
                opacity: 1;
                cursor: pointer !important;
            }
        }
    }
}

.delivery,
.delivery-1,
.delivery-2,
.delivery-3 {
    .upload-wrapper {
        flex-basis: 49% !important;
        margin-bottom: 20px;

        .title {
            left: 10px;
            position: absolute;
            top: 30%;
        }
    }

    .msg-box {
        display: inline-block;
        border: 1px solid var(--brand-color);
        padding: 10px;
        border-radius: 5px;
        height: 90px;
        line-height: 60px;
        font-size: 16px;
        color: var(--dark);
        background-color: #ff8d080f;
        width: 49%;
    }

    .btn-outline-warning,
    .btn-warning {
        padding: 0;
        width: 30px !important;
        height: 30px;
        &:hover {
            background-color: transparent;
        }
        &:disabled {
            border-color: lightgray;
        }
    }
}
.modal-backdrop {
    z-index: 11 !important;
}
.orders-list-popup {
    .modal-header {
        padding: 10px;
    }
    .modal-body {
        max-height: 500px;
        overflow-y: auto;

        .orders-card {
            cursor: pointer;
            border: 1px solid var(--brand-color);
            border-radius: 4px;
            padding: 10px;
            background-color: aliceblue;
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;

            .details {
                display: flex;
                font-weight: bold;
                flex-basis: 85%;
                p {
                    margin: 0px;
                    font-weight: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .delivery,
    .delivery-1,
    .delivery-2,
    .delivery-3 {
        .upload-wrapper {
            flex-basis: 100% !important;
            margin-bottom: 20px;

            .title {
                left: 60px;
            }
        }

        .msg-box {
            height: fit-content;
            line-height: unset;
            width: 100%;
        }

        #uploaded-invoice {
            width: 100%;
        }

        .bg-grey {
            .form-input-group {
                height: 111px !important;

                div.position-relative {
                    width: auto !important;
                }
            }
        }
    }

    .catName{
        width: unset;
        margin: 0 auto;
    }
    .container {
        width: 91.67% !important;
        margin: 40px 15px;
        padding: 0px !important;

        h3 {
            font-size: 24px;
        }

        p.mb-20 {
            font-size: 14px;
        }

        #header {
            width: 100%;
            max-width: unset !important;
        }
    }

    .progress-wrapper {
        gap: 5px;
        margin-bottom: 52px;

        .progress-step {

            span:nth-of-type(2) {
                font-size: 10px !important;
                text-overflow: clip;
                display: inline-block;
                width: -webkit-fill-available;
                white-space: pre-wrap;
            }

            .step {
                width: 14vw;
                height: 14vw;
                font-size: 14px;
                line-height: 35px;
                font-weight: bold;
                max-width: 40px;
                max-height: 40px;
            }
        }

    }

    .progress-wrapper:after {
        top: 20%;
    }

    form {
        width: 100%;

        .btn-brand {
            width: 100%;
        }
    }

    #form3 {
        margin: 0;
        width: 100%;
    }

    #form3 {
        iframe {
            margin-bottom: 25px !important;
        }

        .address-wrapper {
            .did-floating-label-content {
                flex-basis: 100%;
            }
        }

        .checkbox-wrapper {
            font-size: 14px;
        }

        .tabs {

            span {
                padding: 10px 5px;
                font-size: 12px;
            }

            span.active {
                font-size: 12px;
                border-bottom: 2px solid;
            }

        }

        .fields-wrapper {
            border-top: 0px;
            margin-bottom: 20px;
            padding: 15px;
        }

        #price {
            position: absolute;
            top: 31px;
        }

        #commsWrapper {
            background-color: #fff5ed;
        }

        .form-check {
            text-align: left;
            font-size: 18px;
            margin-bottom: 20px;

            .form-check-input {
                width: 20px;
                height: 20px;
            }

            .form-check-label {
                padding-left: 8px;
            }
        }

        #product-details {
            .did-floating-label-content {
                flex-basis: 100%;
            }
        }

        #purchase-details {
            .did-floating-label-content {
                flex-basis: 100%;
            }
        }

        #pick-Up-address {
            .btn-outline {
                margin-top: 20px;
            }
        }

        #issue-details {
            gap: 0px;
            padding-inline: 15px;

            .bg-grey {
                width: 100%;

                & {
                    margin-bottom: 20px;
                }
            }

            .note {
                font-size: 14px;
                text-align: left;
                margin-top: 20px;
            }

            .did-floating-label-content {
                flex-basis: 100%;
            }

            .w-70 {
                svg {
                    right: -3px !important;
                }

                span {
                    font-size: 14px;
                    text-align: left;
                    margin-bottom: 10px;
                }

                .d-inline-flex span {
                    font-size: 10px;
                    text-align: center;
                    margin: 0;
                }

                .d-flex.justify-content-around {
                    justify-content: flex-start !important;
                    gap: 35px;
                }

                img {
                    width: 100%;
                    height: 130px;
                }
            }

            .form-input-group {
                height: auto !important;
                margin-bottom: 20px;

                span {
                    font-size: 14px;
                }

                .text-brand {
                    font-size: 10px;
                    margin-top: 5px;
                }

                div.position-relative {
                    width: auto;
                }
            }
        }

        .btn-outline {
            margin: 0px auto 20px auto;
            font-size: 14px;
            padding: 12px 22px;
        }

        .form-input-group {
            border-radius: 10px;
            height: 50px;
        }

        .file-label {
            margin-bottom: 10px;
            text-align: left;
            font-size: 14px;
        }

        .upload-wrapper,
        .form-input-group {
            height: auto;
            flex-basis: 100%;
        }

        h4 {
            text-align: left;
            font-size: 18px;
        }

        .address-wrapper {
            display: flex;

            #landmark,
            #city {
                width: calc(50%);
            }

            #state,
            #pincode {
                flex-grow: 1;
            }

            .did-floating-label-content {
                flex-basis: 100%;
            }

            .did-floating-input {
                margin-bottom: 0px !important;
            }

            .btn-outline {
                margin-top: 40px;
            }
        }

        #calendar {
            pointer-events: none;
            position: absolute;
            cursor: pointer;
        }

        .btn-brand {
            max-width: 600px;
            margin-inline: auto;
        }

        .file-box-wrapper {
            display: flex;

            .file-box {
                gap: 20px;
                align-items: baseline;

                svg {
                    height: inherit;
                    cursor: pointer;
                    width: auto;
                }
            }
        }

        .bg-grey {
            padding-inline: 15px;
        }

    }

    #personal-details {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        width: 100%;
        padding: 0 10px;

    }

    .d-flex.align-items-center.justify-content-between {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .form-input-group {
        width: 100%;
        height: 60px !important;
    }

    .container {
        h3.mb-5 {
            font-size: 24px;
            margin-bottom: 30px !important;
        }
    }

    .did-floating-label-content {
        margin-bottom: 20px;
    }

    .did-floating-label {
        font-size: 14px;
    }

    .mb-1 {
        margin-bottom: 0px !important;
    }

    input[type="file"] {
        width: 100%;
    }

    #otp-form {
        p {
            font-size: 18px;
            font-weight: 500;
            margin: 0 0 10px 0;
        }

        #change-number {
            font-size: 14px;
            font-weight: normal;
        }

        .input-group {
            flex-wrap: nowrap;
            gap: 6px;
            margin-bottom: 20px;
        }

        .fs-12,
        .text-brand {
            font-size: 12px;
        }

        .form-control--otp {
            width: 50px;
            height: 50px;
        }
    }

    #form3 {
        #issue-details {
            .btn-outline {
                margin: 20px auto 0 auto !important;
            }

            .sample-video-section {
                position: relative;

                .thumbnail-overlay {
                    position: absolute;
                    z-index: 1;
                    background-color: var(--new-text);
                    opacity: 0.6;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 20px;
                }

                svg {
                    position: absolute;
                    z-index: 2;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                }
            }
        }

        #purchase-details {
            .btn-outline {
                margin: 20px auto 0 !important;
            }
        }

        #pick-Up-address {
            .btn-outline {
                margin: 20px auto 0 auto !important;
            }
        }

        #info-wrapper {
            margin-top: 20px;
            grid-template-columns: unset;

            .bg-grey {
                padding: 15px 9px;

                span:first-child {
                    font-size: 14px;
                }
            }

            .item {
                gap: 10px;
                padding: 0px;
                margin-top: 15px;

                .info {
                    span {
                        font-size: 10px;
                        width: auto;
                    }
                }

                img {
                    max-width: 60px;
                    max-height: 60px;
                }
            }
        }
    }
}

// CComplain.scss
.others,
.orders_related,
.delivery,
.general_query {
    padding-inline: 0px !important;
}
.div-fields-wrapper {
    display: flex;
    gap: 25px;
}