.complaint-container {
    padding: 50px;
    background: white;
    border-radius: 10px;
    margin: 50px auto;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    height: auto !important;
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
    div {
        width: 100%;
    }
}

#wrapper {
    display: grid;
    grid-template-columns: 60% 40%;
    width: fit-content;
    gap: 0 40px;
}

p {
    margin-right: 10px;

}

#complaint-svg {
    max-width: 150px;
    margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
    .complaint-container {
        width: 90% !important;
    }

    #wrapper {
        grid-template-columns: unset;
        gap: 0;
        padding-inline-start: unset;
        width: 100% !important;
    }
}