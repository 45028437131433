footer {
    margin-top: auto;
}

footer .social-media-wrapper {
    display: flex;
}

.mobileVisible {
    display: none !important;
}

.footerText {
    margin-top: 24px;
    font-size: 12px;
    font-family: 'basic-sans';
    color: var(--text-white);
    padding: 0 80px 20px 80px;
}

footer .sub {
    background-color: var(--secondary-background-color);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'basic-sans';
    height: 96px;
    padding: 0 52px;
}

footer .sub h3 {
    font-size: 20px;
    margin: 0px;
    font-weight: 600;
}

footer .sub h6 {
    font-size: 12px;
    margin: 0px;
    font-weight: 400;
}

footer .sub input {
    border: 1px solid #B7B7B7;
    background-color: transparent;
    padding: 17px 18px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    width: 50%;
}

footer .sub button {
    width: 161px;
    height: 50px;
    background: var(--text-white) 0% 0% no-repeat padding-box;
    margin-left: 10px;
    color: var(--text-white);
    border: 0px;
    font-weight: 400;
}

footer .main .footer-content .desktopVisible .social-media-icons {
    display: flex;
    align-items: center;
    gap: 20px;
}

footer .sub .social-media-icons {
    margin-left: 45px;
}

footer .sub .social-media-icons img:nth-of-type(2) {
    margin: 0 35px;
}

footer .main {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
}

footer .main .footer-content {
    padding: 40px 80px 0 80px;
    justify-content: space-between;
}
footer .footer-content-wrapper .desktopVisible {
    text-align: left;
}

footer .main .logo-wrapper {
    width: 20%;
    display: flex;
    flex-direction: column;
}

footer .main .logo-wrapper img {
    margin-bottom: 32px;
    display: flex;
    align-self: baseline
}

footer ul {
    padding: 0px;
}

footer a {
    text-decoration: none;
    color: var(--white);
}
footer a:hover{
    text-decoration: none;
    cursor: pointer;
    color: var(--white);
}

footer ul li {
    list-style: none;
    padding-bottom: 20px;
    font-family: 'basic-sans';
    color: var(--text-secondary);
    font-size: 16px;
}

footer .main h3 {
    color: var(--brand-color);
    font-family: 'basic-sans';
    font-size: 16px;
    margin-bottom: 20px;
    text-align: left;
}

footer .main hr {
    border: 1px solid #383838;
    margin-top: 20px;
}

footer .main .payments {
    text-align: left;
    color: #595959;
}

footer .main .payments h3 {
    margin-right: 8px;
    font-family: 'basic-sans';
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

footer .main .payments img {
    margin-right: 15px;
}

footer #input-wrapper {
    width: 40%;
}

footer .main #copyright {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

footer .sub hr:nth-of-type(1) {
    display: none;
}

footer .main #copyright {
    margin-top: 12px;
}

footer .main .footer-content .footer-content-wrapper {
    display: flex;
    gap: 40px;
    text-align: left;
}

footer .footer-content-wrapper h3 {
    margin-top: 0px;
}
footer .main #copyright span {
    color: var(--white);
}

@media only screen and (max-width: 768px) {
    footer .social-media-wrapper {
        margin-bottom: 20px;
        gap: 20px;
        align-items: center;
        padding: 0 20px;
        display: flex !important;
    }

    footer .social-media-icons {
        gap: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    footer .footer-content-wrapper h3 {
        font-size: 12px;
        margin-bottom: 10px;
    }

    footer .sub {
        padding: 28px 0px;
        flex-direction: column;
        height: auto;
    }

    .footerText {
        margin-right: 0px;
        margin-left: 0px;
        padding: unset;
    }

    footer .main h3.m-0 {
        font-size: 12px;
        margin-bottom: 0px;
    }

    footer #input-wrapper {
        margin: 20px 0;
        width: auto;
        padding: 0 20px;
    }

    .sub hr {
        display: block !important;
        border: 1px solid #212121;
        width: 100%;
        margin-bottom: 20px;
    }

    .social-media-wrapper {
        display: flex;
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;
        align-items: center;
    }

    footer .main {
        padding: 22.5px 0px 0px 0px;
    }

    footer .main .footer-content {
        padding: unset;
    }

    footer .main .footer-content .footer-content-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 40px;
    }

    footer .main .content-wrapper {
        flex-wrap: wrap;
        padding: 0px 20px 40px 20px;
    }

    footer .main .logo-wrapper span {
        font-size: 14px;
    }

    footer .main .logo-wrapper {
        text-align: center;
        align-items: center;
        width: 100%;
    }

    footer .main .logo-wrapper img {
        width: auto;
        height: auto;
        align-self: center;
        margin-bottom: 21px;
    }

    footer .main #copyright span {
        font-size: 10px;
        font-family: 'basic-sans';
        color: var(--white);
    }

    footer .main #copyright {
        padding: 10px 20px 13px 20px;
        margin: 0;
        align-items: center;
    }

    footer .sub .social-media-icons {
        margin: 0px;
        margin-bottom: 20px;
        gap: 20px;
        align-items: center;
        padding: 0 20px;
    }

    footer .sub .social-media-icons img:nth-of-type(2) {
        margin: 0 25px;
    }

    .desktopVisible {
        display: none !important;
    }

    footer ul li {
        font-size: 12px;
        padding-bottom: 10px;
    }

    footer ul li:last-child {
        padding-bottom: 0px;
    }

    footer .main .payments {
        align-items: center;
        margin: 23px 0px;
        width: 100%;
        justify-content: space-evenly;
    }

    footer .main .payments img {
        margin-right: 1vw;
    }

    footer .main .payments h3 {
        font-size: 16px;
    }

    footer .main hr {
        margin-top: 0px;
    }

    .hide-for-order-page {
        display: none;
    }

    footer .social-media-icons a {
        height: auto;
    }
}